<script lang="ts" setup>
import { Link, useForm } from '@inertiajs/vue3';
import { useGlobalProps } from '@/Compositions/useGlobalProps';
import LanguageSwitchGuest from '@/Components/Custom/LanguageSwitchGuest.vue';

const { isLocal } = useGlobalProps();

const loginForm = useForm({
    email: '',
    password: '',
});

function login() {
    loginForm.post(route('candidate.login.store'));
}

function loginDemoUser() {
    loginForm.email = 'bader@live.nl';
    loginForm.password = 'password';
    login();
}
</script>

<template>
    <div class="flex flex-col gap-3 w-full h-full justify-center p-4 bg-gray-50 items-center">

        <LanguageSwitchGuest />

        <div class="p-6 border border-gray-200 bg-white flex flex-col justify-between items-center w-full max-w-xl rounded-md gap-6">

            <h1 class="font-bold text-gray-900 text-2xl">
                {{ $t('Login labourlink') }}
            </h1>

            <form @submit.prevent="login" class="flex flex-col gap-6 w-full">
                <div class="flex flex-col w-full gap-1">
                    <label class="text-black">{{ $t('Email') }}</label>
                    <input v-model="loginForm.email" @keydown.enter="login" type="email" class="p-3 border border-gray-300 text-gray-900 text-xl shadow-xs rounded-md tracking-wide" autocomplete="email" />
                    <p v-if="loginForm.errors.email" class="text-red-600">{{ loginForm.errors.email }}</p>
                </div>

                <div class="flex flex-col w-full gap-1">
                    <label class="text-black">{{ $t('Password') }}</label>
                    <input v-model="loginForm.password" @keydown.enter="login" type="password" class="p-3 border border-gray-300 text-gray-900 text-xl shadow-xs rounded-md tracking-wide" autocomplete="current-password" />
                    <p v-if="loginForm.errors.password" class="text-red-600">{{ loginForm.errors.password }}</p>
                </div>
            </form>

            <div @click="login" class="bg-brand-500 text-white text-center cursor-pointer hover:bg-brand-600 py-4 font-bold text-xl w-full rounded-md">
                {{ $t("Login") }}
            </div>

            <div v-if="isLocal" @click="loginDemoUser" class="bg-gray-500 text-white text-center cursor-pointer hover:bg-gray-600 py-4 font-bold text-xl w-full rounded-md">
                Login as a demo user
            </div>

            <i18n-t scope="global" tag="div" keypath="Not yet registered? Click here to register" class="text-gray-500 italic">
                <template #click-here>
                    <Link :href="route('candidate.register.show')" class="text-brand-600 hover:text-brand-700 underline">{{ $t("Click here") }}</Link>
                </template>
            </i18n-t>
        </div>
    </div>
</template>
